<template>
    <div class="container">
        <LoaderCircular class="loader" v-if="loading" />
        <DashboardsModule v-else mode="Communications" />
    </div>
</template>

<script>
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";
import DashboardsModule from "@/components/Evaluation/DashboardsModule.vue";
import { dashboardApiClient } from "@/helpers/evaluation/apiclients";
import DashboardUtilsMixin from "@/helpers/evaluation/util";
import clearDashboardsEventListeners from "@/helpers/evaluation/clearDashboardsEventListeners";

export default {
    name: "ResponseOverviewBridge",
    mixins: [DashboardUtilsMixin],
    components: {
        LoaderCircular,
        DashboardsModule,
    },
    data() {
        return {
            loading: true,
        };
    },
    async mounted() {
        const dashboardsResponse = await dashboardApiClient.get("/dashboards");
        if (!dashboardsResponse.data.length) {
            this.loading = false;
            this.displayMessage(this.translate("DASHBOARD_APP_ERROR_GETTING_DASHBOARDS"), "error");
            return;
        }

        const dashboards = dashboardsResponse.data.map((dashboard) => ({
            id: dashboard.id,
            name: dashboard.name,
            editable: dashboard.editable,
            position: dashboard.dashboardPosition,
            label: dashboard.labelName,
            communicationsDashboard: dashboard.communicationsDashboard,
        }));
        dashboards.sort(this.objectSortFunc("position"));
        this.$store.commit("DashboardStore/setDashboards", dashboards);

        const communicationsDashboard = dashboards.find(
            (dashboard) => dashboard.communicationsDashboard
        );
        this.$store.commit("DashboardStore/setActiveDashboard", communicationsDashboard);

        const dashboardViewsResponse = await dashboardApiClient.get(
            `/dashboards/${communicationsDashboard.id}/dashboard-views`
        );
        if (!dashboardViewsResponse.data.length) {
            this.loading = false;
            this.displayMessage(
                this.translate("DASHBOARD_APP_ERROR_GETTING_DASHBOARD_VIEWS"),
                "error"
            );
            return;
        }

        const communicationsDashboardViews = dashboardViewsResponse.data.map((dashboardView) => {
            const configuration = JSON.parse(dashboardView.configuration);
            if (dashboardView.labelName != null) {
                configuration.label = dashboardView.labelName;
            }

            return {
                id: dashboardView.id,
                title: configuration.title,
                configuration,
                position: dashboardView.dashboardViewPosition,
                dashboard: dashboardView.communicationsDashboard?.id,
                editable: communicationsDashboard.editable,
                template: dashboardView.dashboardViewTemplate,
            };
        });

        communicationsDashboardViews.sort(this.objectSortFunc("position"));
        this.$store.commit(
            "DashboardStore/setActiveDashboardView",
            communicationsDashboardViews[0]
        );

        this.loading = false;
    },
    beforeRouteLeave(to, from, next) {
        // This workaround excludes Dashboards specific stylesheets from other pages
        // by refreshing the page entirely when navigating to another page.
        if (["DashboardsBase", "Dashboards", "ResponseOverview"].includes(to.name)) next();
        else window.location = to.path;
    },
    beforeDestroy() {
        clearDashboardsEventListeners();
    },
};
</script>

<style lang="scss" scoped>
@import "../style_variables/style_variables.scss";

.container {
    @include viewportHeight(100, 56px);
    @media (max-width: 1000px) {
        @include viewportHeight(100, 48px);
    }

    .loader {
        margin: 60px auto 0 auto;
    }
}
</style>
